import React, { useState, useEffect } from 'react';
import './LoanRequests.css';

const LoanRequests = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formData, setFormData] = useState({
    loan_type: '',
    interest_rate: '',
    duration: '',
    amount: ''
  });

  useEffect(() => {
    const fetchLoanRequests = async () => {
      try {
        const response = await fetch('https://srishticooperativesociety.com/api/loan_requests');
        if (!response.ok) {
          throw new Error('Failed to fetch loan requests');
        }
        const data = await response.json();
        
        // Fetch collector names for each loan request
        const loanRequestsWithNames = await Promise.all(
          data.map(async (request) => {
            if (request.collector_assigned) {
              const collectorResponse = await fetch(`https://srishticooperativesociety.com/api/admins/${request.collector_assigned}`);
              const collectorData = await collectorResponse.json();
              return { ...request, collector_name: collectorData.name };
            }
            return { ...request, collector_name: 'N/A' };
          })
        );

        setLoanRequests(loanRequestsWithNames);
        setFilteredRequests(loanRequestsWithNames);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchLoanRequests();
  }, []);

  useEffect(() => {
    const fetchFilteredRequests = async () => {
      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests?search=${searchQuery}`);
        if (!response.ok) {
          throw new Error('Failed to fetch filtered loan requests');
        }
        const data = await response.json();
        
        // Fetch collector names for each filtered request
        const filteredRequestsWithNames = await Promise.all(
          data.map(async (request) => {
            if (request.collector_assigned) {
              const collectorResponse = await fetch(`https://srishticooperativesociety.com/api/admins/${request.collector_assigned}`);
              const collectorData = await collectorResponse.json();
              return { ...request, collector_name: collectorData.name };
            }
            return { ...request, collector_name: 'N/A' };
          })
        );

        // Filter requests by the search query across multiple fields
        const searchQueryLower = searchQuery.toLowerCase();
        const finalFilteredRequests = filteredRequestsWithNames.filter(request =>
          request.firstname.toLowerCase().includes(searchQueryLower) ||
          request.lastname.toLowerCase().includes(searchQueryLower) ||
          request.collector_name.toLowerCase().includes(searchQueryLower) ||
          request.id.toString().includes(searchQueryLower)
        );

        setFilteredRequests(finalFilteredRequests);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchFilteredRequests();
  }, [searchQuery]);

  const handleApprove = async (id) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ approval_status: 'approved' }),
      });

      if (!response.ok) {
        throw new Error('Failed to approve loan request');
      }

      // Update the loan request locally after approval
      const updatedLoanRequests = filteredRequests.map(request =>
        request.id === id ? { ...request, approval_status: 'approved' } : request
      );
      setFilteredRequests(updatedLoanRequests);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEdit = (request) => {
    setSelectedRequest(request);
    setFormData({
      loan_type: request.loan_type,
      interest_rate: request.interest_rate,
      duration: request.duration,
      amount: request.amount,
      collector_assigned: request.collector_assigned
    });
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setSelectedRequest(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${selectedRequest.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to update loan request');
      }

      // Update the loan request locally after update
      const updatedLoanRequests = filteredRequests.map(request =>
        request.id === selectedRequest.id ? { ...request, ...formData } : request
      );
      setFilteredRequests(updatedLoanRequests);
      handleClosePopup();
    } catch (error) {
      setError(error.message);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  return (
    <div className="loan-requests-page">
      <h2>All Loan Requests</h2>
      {error && <p className="error-message">{error}</p>}

      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by firstname, lastname, loan ID, or Field Officer name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <table className="loan-requests-table">
        <thead>
          <tr>
            <th>FirstName</th>
            <th>LastName</th>
            <th>LoanID</th>
            <th>Type</th>
            <th>Interest Rate</th>
            <th>Duration</th>
            <th>Amount</th>
            <th>Field Officer</th>
            <th>Branch ID</th>
            <th>Status</th>
            <th>Posted At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredRequests.map(request => (
            <tr key={request.id}>
              <td>{request.firstname}</td>
              <td>{request.lastname}</td>
              <td>{request.id}</td>
              <td>{request.loan_type}</td>
              <td>{request.interest_rate}%</td>
              <td>{request.duration} months</td>
              <td>₹{request.amount}</td>
              <td>{request.collector_name}</td>
              <td>{request.branch_id}</td>
              <td>{request.approval_status}</td>
              <td>{formatDate(request.posted_at).toLocaleString()}</td>
              <td>
                <>
                  <button onClick={() => handleApprove(request.id)}>Approve</button>
                  <button onClick={() => handleEdit(request)}>Edit</button>
                </>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {popupVisible && selectedRequest && (
        <div className="popup">
          <div className="popup-content">
            <h3>Edit Loan Request</h3>
            <form onSubmit={handleUpdate}>
              <div>
                <label>Loan Type:</label>
                <input
                  type="text"
                  name="loan_type"
                  value={formData.loan_type}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Interest Rate:</label>
                <input
                  type="number"
                  name="interest_rate"
                  value={formData.interest_rate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Duration (Months):</label>
                <input
                  type="number"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label>Amount:</label>
                <input
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label>FieldOfficer:</label>
                <select
    name="collector_assigned"
    value={formData.collector_assigned}
    onChange={handleChange}
    required
  >
    <option value="" disabled>Select loan type</option>
    <option value="11">Chandan Kakati</option>
    <option value="12">Main Branch</option>
    <option value="13">XYZ</option>
    <option value="14">YY1</option>
  </select>
              </div>
              <button type="submit">Update</button>
              <button type="button" onClick={handleClosePopup}>Cancel</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanRequests;
