import React, { useState, useEffect } from 'react';
import './Collections.css';

const Collection = () => {
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [branchId, setBranchId] = useState('');
  const [collectorId, setCollectorId] = useState('');
  const [collectionDate, setCollectionDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api-service.srishticooperativesociety.com/api/collections');
        const data = await response.json();
        setCollections(data);
        setFilteredCollections(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFilter = () => {
    let filtered = collections;

    if (branchId) {
      filtered = filtered.filter(item => item.branch_id === parseInt(branchId));
    }

    if (collectorId) {
      filtered = filtered.filter(item => item.collector_id === parseInt(collectorId));
    }

    if (collectionDate) {
      filtered = filtered.filter(item => item.collection_date.startsWith(collectionDate));
    }

    setFilteredCollections(filtered);
  };

  return (
    <div className="collection-container">
      <h1>Collections</h1>
      <div className="filters">
        <input
          type="text"
          placeholder="Branch ID"
          value={branchId}
          onChange={e => setBranchId(e.target.value)}
        />
        <input
          type="text"
          placeholder="Collector ID"
          value={collectorId}
          onChange={e => setCollectorId(e.target.value)}
        />
        <input
          type="date"
          placeholder="Collection Date"
          value={collectionDate}
          onChange={e => setCollectionDate(e.target.value)}
        />
        <button onClick={handleFilter}>Filter</button>
      </div>
      <table className="collections-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Loan ID</th>
            <th>Collector ID</th>
            <th>Collection Amount</th>
            <th>Collection Date</th>
            <th>User ID</th>
            <th>Branch ID</th>
          </tr>
        </thead>
        <tbody>
          {filteredCollections.map(collection => (
            <tr key={collection.id}>
              <td>{collection.id}</td>
              <td>{collection.loan_id}</td>
              <td>{collection.collector_id}</td>
              <td>{collection.collection_amount}</td>
              <td>{collection.collection_date}</td>
              <td>{collection.user_id}</td>
              <td>{collection.branch_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Collection;
