import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ViewBranch.css';

// Utility function to format numbers in the Indian numbering system
const formatNumber = (num) => {
  const numberString = num.toString().split('.');
  const lastThree = numberString[0].slice(-3);
  const otherNumbers = numberString[0].slice(0, -3);
  const formattedNumber = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastThree;
  return numberString.length > 1 ? formattedNumber + '.' + numberString[1] : formattedNumber;
};

// Utility function to fetch user details
const fetchUserDetails = async (userId) => {
  try {
    const response = await fetch(`https://srishticooperativesociety.com/api/users/${userId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch user details');
    }
    const userData = await response.json();
    return userData;
  } catch (error) {
    console.error('Error fetching user details:', error);
    return null;
  }
};

const ViewBranch = () => {
  const { id } = useParams();
  const [branchData, setBranchData] = useState(null);
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [totalCollections, setTotalCollections] = useState(0);
  const [totalDisbursement, setTotalDisbursement] = useState(0);
  const [totalApprovedAmount, setTotalApprovedAmount] = useState(0);
  const [totalProjectedAmount, setTotalProjectedAmount] = useState(0);
  const [totalFilteredCollections, setTotalFilteredCollections] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cacheKeyPrefix = `viewBranch-${id}`;
    const cachedBranchData = sessionStorage.getItem(`${cacheKeyPrefix}-branchData`);
    const cachedCollections = sessionStorage.getItem(`${cacheKeyPrefix}-collections`);
    const cachedTotalCollections = sessionStorage.getItem(`${cacheKeyPrefix}-totalCollections`);
    const cachedTotalDisbursement = sessionStorage.getItem(`${cacheKeyPrefix}-totalDisbursement`);
    const cachedTotalApprovedAmount = sessionStorage.getItem(`${cacheKeyPrefix}-totalApprovedAmount`);
    const cachedTotalProjectedAmount = sessionStorage.getItem(`${cacheKeyPrefix}-totalProjectedAmount`);

    // Load data from cache if available
    if (cachedBranchData && cachedCollections && cachedTotalCollections && cachedTotalDisbursement && cachedTotalApprovedAmount && cachedTotalProjectedAmount) {
      setBranchData(JSON.parse(cachedBranchData));
      setCollections(JSON.parse(cachedCollections));
      setFilteredCollections(JSON.parse(cachedCollections));
      setTotalCollections(parseFloat(cachedTotalCollections));
      setTotalDisbursement(parseFloat(cachedTotalDisbursement));
      setTotalApprovedAmount(parseFloat(cachedTotalApprovedAmount));
      setTotalProjectedAmount(parseFloat(cachedTotalProjectedAmount));
      setLoading(false);
    } else {
      fetchData();
    }

    // Periodically refresh data every minute
    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [id]);

  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch branch data
      const branchResponse = await fetch(`https://srishticooperativesociety.com/api/branches/${id}`);
      if (!branchResponse.ok) throw new Error('Failed to fetch branch data');
      const branchData = await branchResponse.json();
      setBranchData(branchData);
      sessionStorage.setItem(`viewBranch-${id}-branchData`, JSON.stringify(branchData));

      // Fetch collections data
      const collectionsResponse = await fetch(`https://srishticooperativesociety.com/api/collections/branch/${id}`);
      if (!collectionsResponse.ok) throw new Error('Failed to fetch collections data');
      const collectionsData = await collectionsResponse.json();

      // Fetch user details for collections
      const collectionsWithUsers = await Promise.all(
        collectionsData.map(async (collection) => {
          const userDetails = await fetchUserDetails(collection.user_id);
          return { ...collection, user: userDetails };
        })
      );

      setCollections(collectionsWithUsers);
      setFilteredCollections(collectionsWithUsers);
      sessionStorage.setItem(`viewBranch-${id}-collections`, JSON.stringify(collectionsWithUsers));

      // Fetch total collections
      const totalCollectionsResponse = await fetch(`https://srishticooperativesociety.com/api/total_collections/${id}`);
      if (!totalCollectionsResponse.ok) throw new Error('Failed to fetch total collections');
      const totalCollectionsData = await totalCollectionsResponse.json();
      setTotalCollections(parseFloat(totalCollectionsData));
      sessionStorage.setItem(`viewBranch-${id}-totalCollections`, totalCollectionsData);

      // Fetch total disbursement
      const totalDisbursementResponse = await fetch(`https://srishticooperativesociety.com/api/total_disbursement/${id}`);
      if (!totalDisbursementResponse.ok) throw new Error('Failed to fetch total disbursement');
      const totalDisbursementData = await totalDisbursementResponse.json();
      setTotalDisbursement(parseFloat(totalDisbursementData));
      sessionStorage.setItem(`viewBranch-${id}-totalDisbursement`, totalDisbursementData);

      // Fetch total approved loan amount and projected amount
      const totalApprovedLoanAmountResponse = await fetch(`https://srishticooperativesociety.com/api/total_approved_loan_amount_by_branch/${id}`);
      if (!totalApprovedLoanAmountResponse.ok) throw new Error('Failed to fetch total approved loan amount');
      const totalApprovedLoanAmountData = await totalApprovedLoanAmountResponse.json();
      setTotalApprovedAmount(parseFloat(totalApprovedLoanAmountData.total_approved_amount));
      setTotalProjectedAmount(parseFloat(totalApprovedLoanAmountData.total_projected_amount));
      sessionStorage.setItem(`viewBranch-${id}-totalApprovedAmount`, totalApprovedLoanAmountData.total_approved_amount);
      sessionStorage.setItem(`viewBranch-${id}-totalProjectedAmount`, totalApprovedLoanAmountData.total_projected_amount);

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const filterCollectionsByDateRange = async () => {
    try {
      // Fetch the filtered collections by date range
      const response = await fetch(`https://srishticooperativesociety.com/api/collections/branch/${id}?start_date=${startDate}&end_date=${endDate}`);
      if (!response.ok) throw new Error('Failed to fetch filtered collections');
      
      const filteredData = await response.json();
      
      // Fetch user details for each collection
      const collectionsWithUsers = await Promise.all(
        filteredData.map(async (collection) => {
          const userDetails = await fetchUserDetails(collection.user_id);
          return { ...collection, user: userDetails };
        })
      );
      
      // Filter collections to include only those with status "accepted"
      const acceptedCollections = collectionsWithUsers.filter(collection => collection.status === 'accepted');
      
      // Update state with filtered collections
      setFilteredCollections(acceptedCollections);
      
      // Calculate the total amount of accepted collections
      const totalFilteredAmount = acceptedCollections.reduce((acc, collection) => acc + parseFloat(collection.collection_amount), 0);
      setTotalFilteredCollections(totalFilteredAmount);
      
    } catch (error) {
      setError(error.message);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date') setStartDate(value);
    if (name === 'end_date') setEndDate(value);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    filterCollectionsByDateRange();
  };

  const totalProjectedProfit = totalProjectedAmount - totalApprovedAmount;

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="view-branch-container">
      {branchData && (
        <div className="branch-card">
          <h2>Branch Details</h2>
          <p><strong>Branch Name:</strong> {branchData.branchname}</p>
          <p><strong>Address:</strong> {branchData.address}</p>
          <p><strong>Branch Code:</strong> {branchData.branchcode}</p>
          <p><strong>Created At:</strong> {new Date(branchData.created_at).toLocaleString()}</p>
        </div>
      )}

      <h2>Conditions at Present</h2>
      <div className="total-info">
        <p><strong>Total Collections:</strong> ₹{formatNumber(totalCollections.toFixed(2))}</p>
        <p><strong>Total Disbursement:</strong> ₹{formatNumber(totalDisbursement.toFixed(2))}</p>
        <p><strong>Total Profit:</strong> ₹{formatNumber(totalCollections-totalDisbursement)}</p>
      </div>

<h2>Projections</h2>
      <div className="cards">
        <div className="card">
          <h2>Total Approved Loan</h2>
          <p>₹{formatNumber(totalApprovedAmount.toFixed(2))}</p>
        </div>
        <div className="card">
          <h2>Total Projected Amount</h2>
          <p>₹{formatNumber(totalProjectedAmount.toFixed(2))}</p>
        </div>
        <div className="card">
          <h2>Total Projected Profit</h2>
          <p>₹{formatNumber(totalProjectedProfit.toFixed(2))}</p>
        </div>
      </div>

      <div className="filter-container">
        <h3>Filter Collections by Date Range</h3>
        <form onSubmit={handleFilterSubmit}>
          <div className="filter-input">
            <label htmlFor="start_date">Start Date:</label>
            <input type="date" id="start_date" name="start_date" value={startDate} onChange={handleFilterChange} required />
          </div>
          <div className="filter-input">
            <label htmlFor="end_date">End Date:</label>
            <input type="date" id="end_date" name="end_date" value={endDate} onChange={handleFilterChange} required />
          </div>
          <button type="submit">Filter</button>
        </form>
      </div>

    
      <div className="total-info">
        <p><strong>Total Amount Collected in Range:</strong> ₹{formatNumber(totalFilteredCollections.toFixed(2))}</p>
      </div>

      <div className="collections-table">
        <table>
          <thead>
            <tr>
             
              <th>Collected From</th>
              <th>Collection Date</th>
              <th>Collection Amount</th>
              <th>Collected By</th>
              <th>Branch Approval Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredCollections.length === 0 ? (
              <tr>
                <td colSpan="5">No collections available.</td>
              </tr>
            ) : (
              filteredCollections.map((collection) => (
                <tr key={collection.id}>
                 
                  <td>{collection.collected_from}</td>
                  <td>{new Date(collection.collection_date).toLocaleString()}</td>
                  <td>₹{formatNumber(collection.collection_amount)}</td>
                  <td>{collection.user ? collection.user.firstname + ' ' + collection.user.lastname : 'User not found'}</td>
                  <td>{collection.status} by Branch</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewBranch;
