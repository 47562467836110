import React, { useState, useEffect } from 'react';
import './Disbursement.css';

// Utility function to fetch user details from loan request
const fetchUserDetailsFromLoanRequest = async (loanId) => {
  try {
    const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${loanId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch loan request details');
    }
    const loanRequestData = await response.json();
    return loanRequestData;
  } catch (error) {
    console.error('Error fetching loan request details:', error);
    return null;
  }
};

const Disbursement = () => {
  const [disbursements, setDisbursements] = useState([]);
  const [filteredDisbursements, setFilteredDisbursements] = useState([]);
  const [branchId, setBranchId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch('https://srishticooperativesociety.com/api/transactions');
        if (!response.ok) {
          throw new Error('Failed to fetch transactions');
        }
        const data = await response.json();
        const disbursementData = data.filter(transaction => transaction.transaction_type === 'disbursement');
        const loanRequestPromises = disbursementData.map(async (transaction) => {
          const loanRequestDetails = await fetchUserDetailsFromLoanRequest(transaction.loan_id);
          return { ...transaction, user: loanRequestDetails };
        });
        const transactionsWithUsers = await Promise.all(loanRequestPromises);
        setDisbursements(transactionsWithUsers);
        setFilteredDisbursements(transactionsWithUsers);
        calculateTotalAmount(transactionsWithUsers);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchTransactions();
  }, []);

  const calculateTotalAmount = (data) => {
    const total = data.reduce((acc, transaction) => acc + parseFloat(transaction.transaction_amount), 0);
    setTotalAmount(total);
  };

  const handleFilter = () => {
    let filtered = disbursements;

    if (branchId) {
      filtered = filtered.filter(transaction => transaction.branch_id === parseInt(branchId));
    }
    if (firstName) {
      filtered = filtered.filter(transaction => transaction.user?.firstname.toLowerCase().includes(firstName.toLowerCase()));
    }
    if (lastName) {
      filtered = filtered.filter(transaction => transaction.user?.lastname.toLowerCase().includes(lastName.toLowerCase()));
    }
    if (startDate) {
      filtered = filtered.filter(transaction => new Date(transaction.created_at) >= new Date(startDate));
    }
    if (endDate) {
      filtered = filtered.filter(transaction => new Date(transaction.created_at) <= new Date(endDate));
    }

    setFilteredDisbursements(filtered);
    calculateTotalAmount(filtered);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  return (
    <div className="disbursement-page">
      <h2>Disbursement History</h2>
      {error && <p className="error-message">{error}</p>}
      <div className="filters">
        <input
          type="text"
          placeholder="Branch ID"
          value={branchId}
          onChange={e => setBranchId(e.target.value)}
        />
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
        <label>Start Date :</label>
        <input
          type="date"
          placeholder="Start Date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
        <label>End Date :</label>
        <input
          type="date"
          placeholder="End Date"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />
        <button onClick={handleFilter}>Filter</button>
      </div>
      <h3>Total Disbursement Amount: ₹{totalAmount.toFixed(2)}</h3>
      <table className="transaction-table">
        <thead>
          <tr>
            <th>User Full Name</th>
            <th>Loan ID</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Branch ID</th>
            <th>Mode</th>
          </tr>
        </thead>
        <tbody>
          {filteredDisbursements.map(transaction => (
            <tr key={transaction.id}>
              <td>{transaction.user?.firstname || 'N/A'} {transaction.user?.lastname || 'N/A'}</td>
              <td>{transaction.loan_id}</td>
              <td>₹{transaction.transaction_amount}</td>
              <td>{formatDate(transaction.created_at).toLocaleString()}</td>
              <td>{transaction.branch_id}</td>
              <td>{transaction.mode}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Disbursement;
